<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('templates_email', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row class="my-0">
      <v-col class="d-flex g-5" cols="12">
        <!-- Switch: Activo -->
        <v-switch
          class="v-switch mt-0"
          :label="$t('active')"
          v-model="template.active"
        ></v-switch>

        <!-- Switch: Recuperar contraseña -->
        <v-switch
          class="v-switch mt-0"
          :label="$tc('password_recovery', 1)"
          v-model="template.password"
        ></v-switch>
      </v-col>
      <v-col class="pt-0" cols="12">
        <ValidationProvider
          rules="required|max:32"
          vid="name"
          :name="$tc('name', 1)"
          v-slot="{ errors }"
        >
          <v-text-field
            color="secondary"
            outlined
            :error-messages="errors[0]"
            :label="$tc('name', 1)"
            v-model="template.name"
          />
        </ValidationProvider>
      </v-col>
      <v-col class="pt-0" cols="12">
        <ValidationProvider
          rules="required|max:32"
          vid="subject"
          :name="$tc('subject', 1)"
          v-slot="{ errors }"
        >
          <v-text-field
            color="secondary"
            outlined
            :error-messages="errors[0]"
            :label="$tc('subject', 1)"
            v-model="template.subject"
          />
        </ValidationProvider>
      </v-col>
      <v-col class="pt-0" cols="12">
        <ValidationProvider
          vid="body"
          rules=""
          :name="$tc('body', 1)"
          v-slot="{ errors }"
        >
          <label for="body">{{ $t('body') }}</label>
          <vue-editor id="body" v-model="template.body"></vue-editor>
          <span v-if="errors[0] != '' && errors[0] != null" class="areaErrors">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
      <v-col cols="12">
        <v-textarea
          color="secondary"
          name="sms"
          outlined
          :label="$tc('sms', 1)"
          :rows="3"
          v-model="template.sms"
        />
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'
export default {
  components: {
    VueEditor
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    }
  },
  data() {
    return {
      dialog: false,
      load: false,
      loading: false,
      template: {
        pk: '',
        active: true,
        password: false,
        name: '',
        subject: '',
        body: '',
        sms: '',
        company: ''
      },
      edit: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.template =
            this.toEdit !== null
              ? { ...this.toEdit }
              : {
                  pk: '',
                  active: true,
                  password: false,
                  name: '',
                  subject: '',
                  body: '',
                  sms: ''
                }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  },
  methods: {
    async submit() {
      if (!this.template.company) {
        this.template.company = this.company.pk
      }
      if (!this.loading) {
        try {
          this.loading = true
          const template = !this.edit
            ? await this.$api.mainEmail.create({ form: this.template })
            : await this.$api.mainEmail.edit({
                pk: this.template.pk,
                form: this.template
              })
          this.$emit('done', template.data)
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('template', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
