<route>
{
  "meta": {
    "permission": [
      "main_email.add_email",
      "main_email.view_email",
      "main_email.delete_email",
      "main_email.change_email",
      "emails.view_configtemplate",
      "product.view_producttemplate"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <dialemail v-model="dialog" :to-edit="toEdit" @done="reload = true" />
    <settingTemplate
      ref="settingTemplate"
      :to-edit="toEdit"
      @done="reload = true"
      v-model="dialog2"
    />
    <i-table
      api="mainEmail"
      app="main_email.email"
      :headers="headers"
      :opt="{
        company: this.company.pk,
        main: true
      }"
      :reload.sync="reload"
      :title="$tc('email', 2)"
      @click:create="open()"
      @click:edit="open($event)"
    >
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          color="text-dark"
          icon="fa-cogs"
          text
          :small="$vuetify.breakpoint.smAndDown"
          :title="$t('settings')"
          @click="openSetting()"
        >
        </i-btn>
      </template>
      <template v-slot:item.active="{ item }">
        <v-icon :color="item.active ? 'secondary' : 'red'">
          {{ item.active ? 'fa-check' : 'fa-times' }}
        </v-icon>
      </template>
      <template v-slot:item.body="{ item }">
        <span v-html="item.body"></span>
      </template>
    </i-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import dialemail from '../../components/mainEmail/modal.vue'
import settingTemplate from '../../components/email/settingTemplate.vue'

export default {
  components: {
    dialemail,
    settingTemplate
  },
  data() {
    return {
      templates: [],
      loading: false,
      stages: [],
      reload: false,
      dialog: false,
      dialog2: false,
      toEdit: null
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('subject'),
          value: 'subject'
        },
        {
          text: this.$t('active'),
          align: 'center',
          width: 140,
          value: 'active'
        },
        {
          text:
            this.templateBtn.delete || this.templateBtn.edit
              ? this.$t('action')
              : '',
          align: 'center',
          width: 140,
          value:
            this.templateBtn.delete || this.templateBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      company: 'company/getCompanyData'
    }),
    templateBtn() {
      return {
        delete:
          this.getPermissions([`main_email.delete_email`]) || this.isAdmin,
        edit: this.getPermissions([`main_email.change_email`]) || this.isAdmin
      }
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    openSetting() {
      this.dialog2 = true
      this.$refs.settingTemplate.getSettingTemplate()
    }
  }
}
</script>
